export const PLANS_FTUE = [
  {
    id: 10,
    internal_id: 1,
    free_trial: true,
    title: '7-day free trial',
    statName: 'free trial',
    subTitle: '7-day free trial, then pay $24.90 every month',
    price: '$0',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/now',
    info: '7-day free trial, then pay every month. No commitment, cancel anytime.',
    disclaimer: `You consent to start your Truepath.ai 7-day free trial followed by a monthly subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    type: 'membership',
    amount: 2490,
  },
  {
    id: 10,
    internal_id: 2,
    free_trial: true,
    title: '1 month',
    statName: '1 month unlimited',
    subTitle: '7-day free trial, then pay $24.90 every month',
    price: '$24.90',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/mo',
    info: '7-day free trial, then pay every month. No commitment, cancel anytime.',
    disclaimer: `You consent to start your Truepath.ai 7-day free trial followed by a monthly subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    type: 'membership',
    amount: 2490,
  },
  {
    id: 12,
    internal_id: 3,
    free_trial: true,
    title: '6 months',
    statName: '6 month unlimited',
    subTitle: '7-day free trial, then pay $59.40 every 6 months',
    price: '$9.90',
    periodicity: 'six months',
    fullPrice: '$59.40',
    period: '/mo',
    info: '7 day free trial, then pay upfront every 6 months at 60% off.',
    disclaimer: `You consent to start your Truepath.ai 7-day free trial followed by a 6-month subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $59.40, charged every 6 months until you cancel.`,
    type: 'membership',
    amount: 5940,
  },
];

export const PLANS_UNSUSCRIBED = [
  {
    id: 3,
    title: 'Pay as you go',
    statName: 'pay as you go',
    subTitle: '$4.90 per document',
    price: '$4.90',
    fullPrice: '$4.90',
    periodicity: 'one-time',
    period: '/doc',
    phrase: 'Pay as you go',
    info: 'No commitment',
    type: 'single_document',
    amount: 490,
  },
  {
    id: 10,
    internal_id: 2,
    title: '1 month',
    statName: '1 month unlimited',
    subTitle: '$24.90 every month',
    price: '$24.90',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/mo',
    info: 'No commitment, cancel anytime.',
    disclaimer: `You will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    type: 'membership',
    amount: 2490,
  },
  {
    id: 12,
    internal_id: 3,
    title: '6 months',
    statName: '6 month unlimited',
    subTitle: '$59.40 every 6 months',
    price: '$9.90',
    periodicity: 'six months',
    fullPrice: '$59.40',
    period: '/mo',
    info: '6 month commitment at 60% off.',
    disclaimer: `You will automatically be charged the subscription fee of $59.40, charged every 6 months until you cancel.`,
    type: 'membership',
    amount: 5940,
  },
];

export const PLANS_PAYWALL_MARKETING_CAMPAIGN = [
  {
    id: 10,
    internal_id: 2,
    free_trial: true,
    title: '1 month',
    statName: '1 month unlimited',
    subTitle: '7-day free trial, then pay $24.90 every month, cancel anytime.',
    price: '$24.90',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/mo',
    info: '7-day free trial, then pay every month. No commitment, cancel anytime.',
    disclaimer: `You consent to start your Truepath.ai 7-day free trial followed by a monthly subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    type: 'membership',
    amount: 2490,
  },
  {
    id: 12,
    internal_id: 3,
    free_trial: true,
    title: '6 months',
    statName: '6 month unlimited',
    subTitle:
      '7-day free trial, then pay $59.40 every 6 months, cancel anytime.',
    price: '$9.90',
    periodicity: 'six months',
    fullPrice: '$59.40',
    period: '/mo',
    info: '7 day free trial, then pay upfront every 6 months at 60% off.',
    disclaimer: `You consent to start your Truepath.ai 7-day free trial followed by a 6-month subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $59.40, charged every 6 months until you cancel.`,
    type: 'membership',
    tag: '60% OFF',
    amount: 5940,
  },
];

export const PLANS_UNSUSCRIBED_DISCOUNTS = [
  {
    id: 10,
    internal_id: 2,
    title: '1 month',
    statName: '1 month unlimited',
    subTitle: 'for first month, then $24.90 every month',
    price: '$24.90',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/mo',
    info: 'No commitment, cancel anytime.',
    type: 'membership',
    amount: 2490,
  },
];

export const PLANS_PRICING_EXPERIMENT = [
  {
    id: 10,
    internal_id: 1,
    tier: '1-month',
    free_trial: true,
    title: '1 month',
    statName: '1 month unlimited',
    subTitle: '7 days free, then $24.90 every month',
    price: '$24.90',
    fullPrice: '$24.90',
    transparentTag: '7-day free trial',
    periodicity: 'month',
    period: '/mo',
    info: '7-day free trial, then pay every month. No commitment, cancel anytime.',
    disclaimer: `You consent to start your Truepath.ai 7-day free trial followed by a monthly subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    type: 'membership',
    amount: 2490,
  },
  {
    id: 14,
    tier: '3-month',
    transparentTag: '7-day free trial',
    internal_id: 2,
    free_trial: true,
    title: '3 months',
    statName: '3 month unlimited',
    subTitle: '7 days free, then $59.70 every 3 months',
    price: '$19.90',
    fullPrice: '$59.70',
    periodicity: 'month',
    period: '/mo',
    info: '7-day free trial, then pay every 3 months. No commitment, cancel anytime.',
    disclaimer: `You consent to start your Truepath.ai 7-day free trial followed by a 3-month subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $59.70, charged every 3 months until you cancel.`,
    type: 'membership',
    amount: 5970,
  },
  {
    id: 12,
    tier: '6-month',
    internal_id: 3,
    free_trial: true,
    title: '6 months',
    transparentTag: '7-day free trial',
    statName: '6 month unlimited',
    subTitle: '7 days free, then $59.40 every 6 months',
    price: '$9.90',
    periodicity: 'six months',
    fullPrice: '$59.40',
    period: '/mo',
    info: '7 day free trial, then pay upfront every 6 months at 60% off.',
    disclaimer: `You consent to start your Truepath.ai 7-day free trial followed by a 6-month subscription today. If you do not cancel before the end of the free trial period, you will automatically be charged the subscription fee of $59.40, charged every 6 months until you cancel.`,
    type: 'membership',
    amount: 5940,
  },
  {
    id: 3,
    internal_id: 0,
    title: 'Pay as you go',
    statName: 'pay as you go',
    subTitle: '$4.90 per document',
    price: '$4.90',
    fullPrice: '$4.90',
    periodicity: 'one-time',
    period: '/doc',
    phrase: 'Pay as you go',
    info: 'No commitment',
    type: 'single_document',
    amount: 490,
    oneTimePayment: true,
  },
];
export const PLANS_PRICING_EXPERIMENT_UNSUBSCRIBED = [
  {
    id: 10,
    internal_id: 1,
    tier: '1-month',
    title: '1 month',
    statName: '1 month unlimited',
    subTitle: '$24.90 every month',
    price: '$24.90',
    fullPrice: '$24.90',
    periodicity: 'month',
    period: '/mo',
    info: 'No commitment, cancel anytime.',
    disclaimer: `You will automatically be charged the subscription fee of $24.90, charged every month until you cancel.`,
    type: 'membership',
    amount: 2490,
  },
  {
    id: 14,
    tier: '3-month',
    internal_id: 2,
    title: '3 months',
    statName: '3 month unlimited',
    subTitle: '$59.70 every 3 months',
    price: '$19.90',
    fullPrice: '$59.70',
    periodicity: 'month',
    period: '/mo',
    info: 'No commitment, cancel anytime.',
    disclaimer: `You will automatically be charged the subscription fee of $13.90, charged every 3 months until you cancel.`,
    type: 'membership',
    amount: 5970,
  },
  {
    id: 12,
    tier: '6-month',
    internal_id: 3,
    title: '6 months',
    statName: '6 month unlimited',
    subTitle: '$59.40 every 6 months',
    price: '$9.90',
    periodicity: 'six months',
    fullPrice: '$59.40',
    period: '/mo',
    info: 'No commitment, cancel anytime.',
    disclaimer: `You will automatically be charged the subscription fee of $59.40, charged every 6 months until you cancel.`,
    type: 'membership',
    amount: 5940,
  },
  {
    id: 3,
    internal_id: 0,
    title: 'Pay as you go',
    statName: 'pay as you go',
    subTitle: '$4.90 per document',
    price: '$4.90',
    fullPrice: '$4.90',
    periodicity: 'one-time',
    period: '/doc',
    phrase: 'Pay as you go',
    info: 'No commitment',
    type: 'single_document',
    amount: 490,
    oneTimePayment: true,
  },
];

export const MONTHLY_TIERS = {
  '1-month': {
    id: 10,
    title: 'Unlimited – 1 month',
    price: '$24.90',
    fullPrice: '$24.90',
    commitment: '1 month commitment',
    mostPopular: false,
    trialScreenCopy: '7 days free, then $24.90/mo',
    trialScreenDisclaimer: '(Charged monthly)',
  },
  '3-month': {
    id: 14,
    title: 'Unlimited – 3 months',
    price: '$19.90',
    fullPrice: '$59.70',
    commitment: '3 months commitment',
    mostPopular: true,
    trialScreenCopy: '7 days free, then $19.90/mo',
    trialScreenDisclaimer: '($59.70 charged every 3 months)',
  },
  '6-month': {
    id: 12,
    price: '$9.90',
    title: 'Unlimited – 6 months',
    fullPrice: '$59.40',
    commitment: '6 months commitment',
    mostPopular: false,
    trialScreenCopy: '7 days free, then $9.90/mo',
    trialScreenDisclaimer: '($59.40 charged every 6 months)',
  },
  'pay-as-you-go': {
    id: 3,
    title: 'Pay as you go',
    price: '$4.90',
    fullPrice: '$4.90',
    onePayment: true,
  },
};
