import { useContext, useEffect, useState } from 'react';
import { isNil } from 'lodash';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import AppContext from '../store/AppContext';

export default function PrivateRoutes({ superUser = false }) {
  const [state] = useContext(AppContext);
  const { loggedIn, currentUser, loadingLoggedInUser } = state;
  const location = useLocation();
  const [canAccessRoute, setCanAccessRoute] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');

  useEffect(() => {
    // LoggedIn state starts in null so we don't do anything yet
    if (isNil(loggedIn)) {
      setCanAccessRoute(false);
      setRedirectTo('');
      return;
    }

    // If loggedIn state is false we should redirect to login
    if (loggedIn === false) {
      setCanAccessRoute(false);
      setRedirectTo('/login');
      return;
    }

    // If user data still loading we don't do anything yet
    if (loadingLoggedInUser) {
      setCanAccessRoute(false);
      setRedirectTo('');
      return;
    }

    // If superUser access required and user is not super_user, redirect to dashboard
    if (superUser && !currentUser?.super_user) {
      setCanAccessRoute(false);
      setRedirectTo('/dashboard');
      return;
    }

    // User should be able to access the route
    setCanAccessRoute(true);
    setRedirectTo('');
  }, [currentUser, loadingLoggedInUser, loggedIn, superUser]);

  return canAccessRoute ? (
    <Outlet />
  ) : redirectTo ? (
    <Navigate to={redirectTo} replace state={{ redirectTo: location }} />
  ) : null;
}
