import { lazy, Suspense } from 'react';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { Route, Routes } from 'react-router-dom';

import { FeatureFlags } from '../constants/featureFlags';
import useFeatureFlag from '../hooks/useFeatureFlag';
import useTrueprofileEnabled from '../hooks/useTrueprofileEnabled';
import useV4Features from '../hooks/useV4Features';
import PrivateLayout from '../layout/PrivateLayout';
import PublicLayout from '../layout/PublicLayout';
import { useAppContext } from '../store/AppContext';
import ErrorBoundary from './ErrorBoundary';
import KoiLandingPage from './LandingPage/KoiLandingPage';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

const AccountSection = lazy(
  () => import('./Account/AccountSection/AccountSection'),
);
const AssessmentContentV2 = lazy(() => import('./Results/AssessmentContentV2'));
const MembershipSection = lazy(
  () => import('./Account/MembershipSection/MembershipSection'),
);
const PaymentSection = lazy(
  () => import('./Account/PaymentSection/PaymentSection'),
);
const EmailVerification = lazy(() => import('./Auth/EmailVerification'));
const LoginPage = lazy(() => import('./Auth/LoginPage'));
const Logout = lazy(() => import('./Auth/Logout'));
const ResetPassword = lazy(() => import('./Auth/ResetPassword'));
const SignUpPage = lazy(() => import('./Auth/SignUpPage'));
const DefaultTemplate = lazy(() => import('./Builder/DefaultTemplate'));
const ResumeDownload = lazy(() => import('./Builder/ResumeDownload'));
const PaymentPlans = lazy(() => import('./Checkout/PaymentPlans'));
const InvitePage = lazy(() => import('./Invite/InvitePage'));
const DoInvite = lazy(() => import('./Invite/DoInvite'));
const Dashboard = lazy(() => import('./Dashboard/Dashboard'));
const DashboardV2 = lazy(() => import('./Dashboard/V2/DashboardV2'));
const Design = lazy(() => import('./Design/Design'));
const DocumentBuilder = lazy(() => import('./DocumentBuilder/DocumentBuilder'));
const DocumentBuilderPage = lazy(
  () => import('./DocumentBuilderPage/DocumentBuilderPage'),
);
const DocumentBuilderContent = lazy(
  () => import('./DocumentBuilderPage/DocumentBuilderContent'),
);
const EditCoverLetterPage = lazy(
  () => import('./EditCoverLetterPage/EditCoverLetterPage'),
);
const EditCoverLetterTemplatePage = lazy(
  () => import('./EditCoverLetterTemplatePage/EditCoverLetterTemplatePage'),
);
const EditResumePage = lazy(() => import('./EditResumePage/EditResumePage'));
const EditResumeTemplatePage = lazy(
  () => import('./EditResumeTemplatePage/EditResumeTemplatePage'),
);
const EditResumeSkillsPage = lazy(
  () => import('./EditResumeSkillsPage/EditResumeSkillsPage'),
);
const StaticPlaceholder = lazy(() => import('./LandingPage/StaticPlaceholder'));
const AssessmentResults = lazy(() => import('./Results/AssessmentResults'));

const ResumeBuilderPage = lazy(
  () => import('./ResumeBuilderPage/ResumeBuilderPage'),
);
const SubscriptionCanceledPage = lazy(
  () => import('./SubscriptionCanceledPage/SubscriptionCanceledPage'),
);
const TakeTest = lazy(() => import('./TakeTest/TakeTest'));
const AccountPage = lazy(() => import('./Account/AccountPage'));
const AdminPortal = lazy(() => import('./AdminPortal/AdminPortal'));
const ProfilePage = lazy(() => import('./ProfilePage/ProfilePage'));
const ProfileSkillsPage = lazy(
  () => import('./ProfileSkillsPage/ProfileSkillsPage'),
);
const ProfileEducationPage = lazy(
  () => import('./ProfileEducationPage/ProfileEducationPage'),
);
const ProfileHobbyPage = lazy(
  () => import('./ProfileHobbyPage/ProfileHobbyPage'),
);
const ProfileLicensePage = lazy(
  () => import('./ProfileLicensePage/ProfileLicensePage'),
);
const ProfileAwardPage = lazy(
  () => import('./ProfileAwardPage/ProfileAwardPage'),
);
const ProfileLanguagePage = lazy(
  () => import('./ProfileLanguagePage/ProfileLanguagePage'),
);
const ProfileAssociationPage = lazy(
  () => import('./ProfileAssociationPage/ProfileAssociationPage'),
);
const ProfilePublicationPage = lazy(
  () => import('./ProfilePublicationPage/ProfilePublicationPage'),
);
const ProfileVolunteerPage = lazy(
  () => import('./ProfileVolunteerPage/ProfileVolunteerPage'),
);

export default function AppRoutes() {
  const showReferralUI = useFeatureFlag(FeatureFlags.V_1_3_REFERRAL_UI, false);
  const V_4_FEATURES_ENABLED = useV4Features();
  const trueprofileEnabled = useTrueprofileEnabled();
  const { appState } = useAppContext();
  const { loggedIn } = appState;

  return (
    <ErrorBoundary>
      <Suspense fallback={null}>
        <Routes>
          <Route element={<PublicRoutes onlyGuests />}>
            <Route path="/login" element={<LoginPage />} exact />
            <Route path="/password-reset" element={<ResetPassword />} exact />
            <Route path="/static" element={<StaticPlaceholder />} exact />
          </Route>

          <Route element={<PublicRoutes />}>
            <Route path="/assessment" element={<TakeTest />} exact />
            <Route path="/sign-up" element={<SignUpPage />} exact />
            <Route path="/design" element={<Design />} exact />
            <Route
              path="/invite/:referrer_invite_link"
              element={<DoInvite />}
              exact
            />
            <Route
              path="/profile/:hash"
              element={<AssessmentResults publicPage />}
              exact
            />
            <Route
              path="/resume-builder"
              element={<ResumeBuilderPage />}
              exact
            />
          </Route>

          <Route element={loggedIn ? <PrivateLayout /> : <PublicLayout />}>
            <Route
              path="/u/:vanityUrl"
              element={<ProfilePage publicProfile />}
              exact
            />
          </Route>

          <Route element={<PrivateRoutes />}>
            {trueprofileEnabled ? (
              <Route element={<PrivateLayout />}>
                <Route path="/dashboard" element={<DashboardV2 />} exact />
                <Route path="/profile" element={<ProfilePage />} exact />
                <Route
                  path="/profile/skills"
                  element={<ProfileSkillsPage />}
                  exact
                />
                <Route
                  path="/profile/educations/new"
                  element={<ProfileEducationPage />}
                  exact
                />
                <Route
                  path="/profile/educations/:educationId"
                  element={<ProfileEducationPage />}
                  exact
                />
                <Route
                  path="/profile/hobbies/new"
                  element={<ProfileHobbyPage />}
                  exact
                />
                <Route
                  path="/profile/hobbies/:hobbyId"
                  element={<ProfileHobbyPage />}
                  exact
                />
                <Route
                  path="/profile/licenses/new"
                  element={<ProfileLicensePage />}
                  exact
                />
                <Route
                  path="/profile/licenses/:licenseId"
                  element={<ProfileLicensePage />}
                  exact
                />
                <Route
                  path="/profile/awards/new"
                  element={<ProfileAwardPage />}
                  exact
                />
                <Route
                  path="/profile/awards/:awardId"
                  element={<ProfileAwardPage />}
                  exact
                />
                <Route
                  path="/profile/languages/new"
                  element={<ProfileLanguagePage />}
                  exact
                />
                <Route
                  path="/profile/languages/:languageId"
                  element={<ProfileLanguagePage />}
                  exact
                />

                <Route
                  path="/profile/associations/new"
                  element={<ProfileAssociationPage />}
                  exact
                />
                <Route
                  path="/profile/associations/:associationId"
                  element={<ProfileAssociationPage />}
                  exact
                />
                <Route
                  path="/profile/publications/new"
                  element={<ProfilePublicationPage />}
                  exact
                />
                <Route
                  path="/profile/publications/:publicationId"
                  element={<ProfilePublicationPage />}
                  exact
                />
                <Route
                  path="/profile/volunteers/new"
                  element={<ProfileVolunteerPage />}
                  exact
                />
                <Route
                  path="/profile/volunteers/:volunteerExperienceId"
                  element={<ProfileVolunteerPage />}
                  exact
                />
                <Route
                  path="/assessment-results"
                  element={<AssessmentContentV2 fromDashboard />}
                  exact
                />
                <Route
                  path="/document-builder"
                  element={<DocumentBuilderContent />}
                  exact
                />
                <Route path="/account" element={<AccountSection />} exact />
                <Route
                  path="/subscription"
                  element={<MembershipSection />}
                  exact
                />
                <Route path="/payments" element={<PaymentSection />} exact />
              </Route>
            ) : (
              <>
                <Route path="/dashboard" element={<Dashboard />} exact />
                <Route
                  path="/document-builder"
                  element={
                    V_4_FEATURES_ENABLED ? (
                      <DocumentBuilderPage />
                    ) : (
                      <DocumentBuilder />
                    )
                  }
                  exact
                />
                <Route path="/settings" element={<AccountPage />} exact />
              </>
            )}

            <Route path="/plans" element={<PaymentPlans />} exact />
            {showReferralUI && (
              <Route path="/invite" element={<InvitePage />} exact />
            )}
            <Route path="/results" element={<AssessmentResults />} exact />
            <Route path="/verify" element={<EmailVerification />} exact />
            <Route
              path="/builder/cover-template"
              element={<DefaultTemplate type="cover_letter" />}
              exact
            />
            <Route
              path="/builder/resume-template"
              element={<DefaultTemplate type="resume" />}
              exact
            />
            <Route path="/view-resume/:id" element={<ResumeDownload />} exact />
            <Route path="/edit-resume/:id" element={<EditResumePage />} exact />
            <Route
              path="/edit-resume/:id/template"
              element={<EditResumeTemplatePage />}
              exact
            />
            <Route
              path="edit-resume/:id/skills"
              element={<EditResumeSkillsPage />}
              exact
            />
            <Route
              path="/edit-cover-letter/:id"
              element={<EditCoverLetterPage />}
              exact
            />
            <Route
              path="/edit-cover-letter/:id/template"
              element={<EditCoverLetterTemplatePage />}
              exact
            />
            <Route
              path="/subscription-canceled"
              element={<SubscriptionCanceledPage />}
              exact
            />
          </Route>

          <Route element={<PrivateRoutes superUser />}>
            <Route path="/admin" element={<AdminPortal />} exact />
          </Route>

          <Route path="/logout" element={<Logout />} exact />
          <Route exact path="oauth/linkedin" element={<LinkedInCallback />} />
          <Route exact path="oauth/office365" element={<LinkedInCallback />} />

          <Route path="*" element={<KoiLandingPage />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}
