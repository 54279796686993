import { useLocation, useSearchParams } from 'react-router-dom';

import { FeatureFlags } from '../constants/featureFlags';
import useFeatureFlag from '../hooks/useFeatureFlag';

const useLaunchPaywallModal = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const utmSource = searchParams.get('utm_source');
  const utmMedium = searchParams.get('utm_medium');
  const utmCampaign = searchParams.get('utm_campaign');
  const tab = searchParams.get('tab');
  const onDashboard = location.pathname.includes('dashboard');

  const payAsYouGoPaywall = useFeatureFlag(
    FeatureFlags.PAY_AS_YOU_GO_PAYWALL_MARKETING_CAMPAIGN,
    false,
  );

  const usePaywallMarketingCampaign =
    payAsYouGoPaywall &&
    onDashboard &&
    tab === 'documents' &&
    utmSource === 'brevo' &&
    utmMedium === 'email' &&
    utmCampaign === 'paywall_modal_launch';

  return {
    utmSource,
    utmMedium,
    utmCampaign,
    usePaywallMarketingCampaign,
  };
};

export default useLaunchPaywallModal;
