import { createContext, useContext, useEffect, useState } from 'react';

import { loadSpace } from '@usersnap/browser';

import { getEnvSetting } from '../utils/envSettings';

import { useAppContext } from './AppContext';

const usersnapApiKey = getEnvSetting('USERSNAP_GLOBAL_API_KEY');

export const UsersnapContext = createContext(null);

export const UsersnapProvider = ({ children }) => {
  const { appState } = useAppContext();
  const { currentUser } = appState;
  const [usersnapApi, setUsersnapApi] = useState(null);
  const [initParams, setInitParams] = useState({});

  useEffect(() => {
    if (currentUser) {
      setInitParams({
        user: {
          userId: currentUser.id,
          email: currentUser.email,
        },
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (usersnapApiKey) {
      loadSpace(usersnapApiKey)
        .then((api) => {
          api.init(initParams);
          setUsersnapApi(api);
        })
        .catch((error) => {
          console.error('Error loading Usersnap API:', error);
        });
    }
  }, [initParams]);

  return (
    <UsersnapContext.Provider value={usersnapApi}>
      {children}
    </UsersnapContext.Provider>
  );
};

export function useUsersnapApi() {
  return useContext(UsersnapContext);
}
