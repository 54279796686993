import './PublicLayout.scss';

import { Outlet } from 'react-router-dom';

import Header from './header/Header';

export default function PublicLayout() {
  return (
    <div className="__public-layout">
      <Header />
      <main className="main-content">
        <Outlet />
      </main>
    </div>
  );
}
