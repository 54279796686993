import { useContext } from 'react';

import useTrueprofileEnabled from '../../hooks/useTrueprofileEnabled';
import { useWindowSize } from '../../hooks/useWindowSize';
import AppContext from '../../store/AppContext';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

import './NavigationHeader.scss';

export default function NavigationHeader({ logoOnly, children }) {
  const { isMobile, isTablet } = useWindowSize();
  const [state] = useContext(AppContext);
  const { currentUser } = state;
  const superUserMode =
    currentUser?.super_user && window.location.pathname === '/admin';
  const trueprofileEnabled = useTrueprofileEnabled();

  return (
    <header className="__navigation-header">
      {(trueprofileEnabled && isTablet) || isMobile ? (
        <MobileHeader superUser={superUserMode}>{children}</MobileHeader>
      ) : (
        <DesktopHeader superUser={superUserMode}>{children}</DesktopHeader>
      )}
    </header>
  );
}
