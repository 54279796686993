import React from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { ENV_LOCAL, ENV_QA, getEnvSetting } from '../utils/envSettings';

export const bugsnagKey = getEnvSetting('BUGSNAG_API_KEY');

const releaseStage = ENV_LOCAL
  ? 'local'
  : ENV_QA
    ? 'development'
    : 'production';

Bugsnag.start({
  apiKey: bugsnagKey,
  plugins: [new BugsnagPluginReact()],
  releaseStage,
  enabledReleaseStages: ['production', 'development'],
});

export const ErrorBoundary =
  Bugsnag.getPlugin('react').createErrorBoundary(React);
