import cn from 'classnames';
import { Link, NavLink } from 'react-router-dom';

import Tracking, { trackEvent } from '../utils/tracking';

export default function TLink({
  nav,
  event,
  properties,
  children,
  className,
  onClick,
  ...props
}) {
  const handleClick = () => {
    onClick?.();

    if (event) {
      trackEvent(event, properties);
    } else {
      Tracking.trackUserClicked({ widget_name: children, ...properties });
    }
  };

  const LinkComponent = nav ? NavLink : Link;

  return (
    <LinkComponent
      className={cn('__t-link', className)}
      {...props}
      onClick={handleClick}
    >
      {children}
    </LinkComponent>
  );
}
