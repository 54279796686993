import ScrollToTop from '../../utils/ScrollToTop';
import NavigationHeader from '../navigation/NavigationHeader';

export default function Header() {
  return (
    <>
      <NavigationHeader />
      <ScrollToTop />
    </>
  );
}
