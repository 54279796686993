import { AppContextProvider } from './AppContext';
import { DocumentBuilderProvider } from './DocumentBuilderContext';
import { ResumeBuilderProvider } from './ResumeBuilderContext';
import { UsersnapProvider } from './UsersnapContext';

export default function ContextProviders({ children }) {
  return (
    <AppContextProvider>
      <ResumeBuilderProvider>
        <DocumentBuilderProvider>
          <UsersnapProvider>{children}</UsersnapProvider>
        </DocumentBuilderProvider>
      </ResumeBuilderProvider>
    </AppContextProvider>
  );
}
