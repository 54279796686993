import GTM_EVENTS_MAP from '../constants/GTMEvents';
import { getEnvironment, getEnvSetting } from '../utils/envSettings';

export default function useGTM() {
  const setUserData = (currentUser) => {
    const parts = currentUser?.user_name?.split(' ');

    window.gtag('set', {
      user_data: {
        email: currentUser.email,
        address: {
          first_name: parts?.[0] || '',
          last_name: parts?.[1] || '',
        },
      },
    });
  };

  const trackGTMEvent = (eventKey, additionalParams = {}, url = null) => {
    var callback = function () {
      if (url) {
        window.location = url;
      }
    };

    const { currentUser, ...restParams } = additionalParams;
    const environment = getEnvironment();
    const eventId = GTM_EVENTS_MAP[eventKey][environment];
    const GOOGLE_TAG_ID = getEnvSetting('GOOGLE_TAG_ID');

    currentUser && setUserData(currentUser);

    if (GOOGLE_TAG_ID !== '{REACT_APP_GOOGLE_TAG_ID}' && eventId) {
      window.gtag('event', 'conversion', {
        send_to: `${GOOGLE_TAG_ID}/${eventId}`,
        event_callback: callback,
        ...restParams,
      });
    }
    return false;
  };

  return { trackGTMEvent };
}
