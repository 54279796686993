import { useState } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as PlusIcon } from '../../assets/icons/koi/plus-icon.svg';
import PurchaseModalV2 from '../../common/PurchaseModalV2/PurchaseModalV2';
import useStartDocumentBuilder from '../../hooks/useStartDocumentBuilder';
import KoiButton from '../../layout/koi/KoiButton';
import { useAppContext } from '../../store/AppContext';
import Tracking from '../../utils/tracking';
import { canCreateDocuments } from '../../utils/user';
import { startResumeBuilderFlow } from '../../utils/util';
import NotReadyForFreeTrialModal from '../Trueprofile/NotReadyForFreeTrial';

import './CreateDocumentButton.scss';

export default function CreateDocumentButton({
  showIcon = false,
  className,
  customLabel,
  variant = 'primary',
  onClick,
}) {
  const navigate = useNavigate();
  const { appState, appDispatch } = useAppContext();
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [notReadyForFreeTrialScreen, setNotReadyForFreeTrialScreen] =
    useState(false);
  const { currentUser, hasCoreData } = appState;
  const startDocumentBuilder = useStartDocumentBuilder();

  const handleClick = () => {
    Tracking.trackUserClicked({
      widget_name: 'Create Document',
      from_view: 'Dashboard',
    });

    if (!canCreateDocuments(currentUser)) {
      setShowPurchaseModal(true);
      return;
    }

    if (hasCoreData) {
      startDocumentBuilder();
    } else {
      startResumeBuilderFlow(appDispatch);
      navigate('/resume-builder');
    }
    onClick && onClick();
  };

  return (
    <>
      <KoiButton
        icon={showIcon ? <PlusIcon /> : null}
        data-cy="create-document-button"
        className={cn('__create-document-button', className)}
        variant={variant}
        onClick={handleClick}
      >
        {customLabel ? customLabel : 'Create'}
      </KoiButton>
      <PurchaseModalV2
        show={showPurchaseModal}
        onHide={(avoidTriggerFakeDoor) => {
          setShowPurchaseModal(false);

          if (!avoidTriggerFakeDoor && !currentUser.subscribed) {
            setNotReadyForFreeTrialScreen(true);
            Tracking.trackUserClicked({
              widget_name: 'ftue-paywall-modal-close',
            });
          }
        }}
      />

      <NotReadyForFreeTrialModal
        show={notReadyForFreeTrialScreen}
        onCloseSplashScreen={(event) => {
          setNotReadyForFreeTrialScreen(false);
          if (event !== 'AVOID_LOG') {
            Tracking.trackUserClicked({
              widget_name: 'not-ready-for-free-trial-modal-close',
            });
          }
        }}
      />
    </>
  );
}
