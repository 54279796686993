import classNames from 'classnames';

import usePaywallMonthDiscount from '../..//hooks/usePaywallMonthDiscount';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/koi/arrow-left.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/koi/check-icon.svg';
import { ReactComponent as ResumeIcon } from '../../assets/icons/koi/resume-paywall-icon.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/koi/small-circle-check.svg';
import useLaunchPaywallModal from '../../hooks/useLaunchPaywallModal';
import usePaywallDiscountModal from '../../hooks/usePaywallDiscountModal';
import usePricingExperimentFlag from '../../hooks/usePricingExperimentFlag';
import { useWindowSize } from '../../hooks/useWindowSize';
import KoiButton from '../../layout/koi/KoiButton';
import KoiTitle, { Highlight } from '../../layout/koi/KoiTitle';
import { getDiscountedPrice } from '../../utils/formatter';

export function Description({ items, onBack, currentUser }) {
  const { usePaywallMarketingCampaign } = useLaunchPaywallModal();
  const { utmDiscount, showPaywallDiscountModal } = usePaywallDiscountModal();
  const PRICING_EXPERIMENT_ENABLED = usePricingExperimentFlag();
  const { isMobile } = useWindowSize();
  const isUnsubscribed = currentUser?.unsubscribed;
  const isSubscribed = currentUser?.subscribed;

  const { showPaywallMonthDiscount } = usePaywallMonthDiscount();
  const useMonthDiscount = !isSubscribed && showPaywallMonthDiscount;

  const renderTitle = () => {
    if (useMonthDiscount) {
      return <KoiTitle>Enjoy 100% off a 1-month subscription.</KoiTitle>;
    }

    if (usePaywallMarketingCampaign) {
      return (
        <KoiTitle>
          Start your <Highlight whiteHighlight>7-day free trial</Highlight> to
          create unlimited documents.
        </KoiTitle>
      );
    }

    if (isUnsubscribed && showPaywallDiscountModal) {
      return (
        <KoiTitle>
          <Highlight whiteHighlight>Welcome back!</Highlight> Enjoy your{' '}
          {utmDiscount}% discount off a 1-month subscription.
        </KoiTitle>
      );
    }

    if (PRICING_EXPERIMENT_ENABLED) {
      return <KoiTitle>Choose a plan to download your resume.</KoiTitle>;
    }

    return (
      <KoiTitle>
        <Highlight whiteHighlight>Choose the plan</Highlight> that's right for
        you.
      </KoiTitle>
    );
  };

  return (
    <>
      <div className="top-content">
        {PRICING_EXPERIMENT_ENABLED && !isUnsubscribed && (
          <div className="arrow-icon-container" onClick={onBack}>
            <ArrowLeft />
          </div>
        )}
        <ResumeIcon className="resume-icon" />
        {renderTitle()}
      </div>
      <div className="bottom-content">
        <p className="label">
          {showPaywallDiscountModal
            ? 'This plan includes:'
            : ` All ${PRICING_EXPERIMENT_ENABLED && 'monthly'} plans include:`}
        </p>
        <div className="items-container">
          {PRICING_EXPERIMENT_ENABLED &&
            !isMobile &&
            !isUnsubscribed &&
            !useMonthDiscount && (
              <div className="item">
                <div>
                  <CheckmarkIcon />
                </div>
                <p className="item-label-variant">7 day free trial</p>
              </div>
            )}
          {items.map((item, index) => (
            <div
              className={classNames('item', {
                'item-variant': PRICING_EXPERIMENT_ENABLED && isMobile,
              })}
              key={index}
            >
              <div>
                {PRICING_EXPERIMENT_ENABLED ? <CheckmarkIcon /> : item.icon}
              </div>
              <p
                className={classNames('item-label', {
                  'item-label-variant': PRICING_EXPERIMENT_ENABLED,
                })}
              >
                {item.label}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export function PlanSelection({
  currentUser,
  plans,
  selectedPlan,
  onContinue,
  onHide,
  onSelect,
}) {
  const { utmDiscount, showPaywallDiscountModal } = usePaywallDiscountModal();
  const PRICING_EXPERIMENT_ENABLED = usePricingExperimentFlag();
  const { isMobile } = useWindowSize();

  const { showPaywallMonthDiscount } = usePaywallMonthDiscount();
  const useMonthDiscount = !currentUser?.subscribed && showPaywallMonthDiscount;

  return (
    <>
      <div className="top-content">
        <div
          className="close-icon"
          onClick={() => {
            onHide();
          }}
        >
          <CloseIcon />
        </div>
        <div
          className={classNames('choose-plan-label', {
            hide:
              showPaywallDiscountModal ||
              PRICING_EXPERIMENT_ENABLED ||
              useMonthDiscount,
          })}
        >
          Choose your plan below.
        </div>

        <div className="plans" data-cy="plans-screen">
          {plans.map((plan) => (
            <div
              className={classNames('plan', {
                'smaller-plan': PRICING_EXPERIMENT_ENABLED && !useMonthDiscount,
                'plan-selected': selectedPlan.title === plan.title,
                'white-plan': plan.oneTimePayment,
              })}
              key={plan.title}
              onClick={() => {
                onSelect(plan);
              }}
            >
              <div>
                <p
                  className={classNames('plan-title', {
                    'plan-title-variant': PRICING_EXPERIMENT_ENABLED,
                  })}
                >
                  {plan.title}

                  {plan.transparentTag && (
                    <span className="plan-transparent-tag">
                      {plan.transparentTag}
                    </span>
                  )}
                </p>
                <p
                  className={classNames('plan-subtitle', {
                    'plan-subtitle-variant': PRICING_EXPERIMENT_ENABLED,
                  })}
                >
                  {showPaywallDiscountModal
                    ? `${getDiscountedPrice(plan.price, utmDiscount)} ${plan.subTitle}`
                    : useMonthDiscount
                      ? `${getDiscountedPrice(plan.price, 100)} ${plan.subTitle}`
                      : plan.subTitle}
                </p>
              </div>
              <div
                className={classNames('plan-price', {
                  'left-margin': showPaywallDiscountModal || useMonthDiscount,
                })}
              >
                <div className="flex">
                  <div className="prices">
                    <p
                      className={classNames('price', {
                        'plan-price-variant': PRICING_EXPERIMENT_ENABLED,
                        strikethrough:
                          showPaywallDiscountModal || useMonthDiscount,
                      })}
                    >
                      {plan.price}
                    </p>
                    {showPaywallDiscountModal && (
                      <p className="price discounted-price">
                        {getDiscountedPrice(plan.price, utmDiscount)}
                      </p>
                    )}
                    {useMonthDiscount && (
                      <p className="price discounted-price">
                        {getDiscountedPrice(plan.price, 100)}
                      </p>
                    )}
                  </div>
                  <p className="plan-period">{plan.period || ''}</p>
                </div>
                {selectedPlan.title === plan.title && (
                  <div
                    className={classNames('plan-selected-icon-wrapper', {
                      'smaller-plan-icon-wrapper': PRICING_EXPERIMENT_ENABLED,
                    })}
                  >
                    <CheckIcon />
                  </div>
                )}
                {plan.tag && <div className="plan-tag-wrapper">{plan.tag}</div>}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={
          PRICING_EXPERIMENT_ENABLED && isMobile
            ? 'continue-btn-wrapper-variant'
            : 'continue-btn-wrapper'
        }
      >
        <KoiButton
          className={
            PRICING_EXPERIMENT_ENABLED && isMobile
              ? 'continue-btn-variant'
              : 'continue-btn'
          }
          data-cy="continue-btn"
          variant="primary"
          disabled={!selectedPlan.id}
          onClick={onContinue}
        >
          {PRICING_EXPERIMENT_ENABLED ? 'Select plan' : 'Continue'}
        </KoiButton>
      </div>
    </>
  );
}
