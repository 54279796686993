import { Component } from 'react';

import Bugsnag from '@bugsnag/js';

// Define a regular expression to detect chunk loading errors
const chunkErrorRegex = /Loading (CSS )?chunk \d+ failed/;

class ErrorBoundary extends Component {
  // Handle errors in the component
  componentDidCatch(error, info) {
    // Check if the error is a chunk loading error
    if (chunkErrorRegex.test(error.message)) {
      this.handleChunkLoadError(error);
    } else {
      // Log other errors for debugging
      Bugsnag.notify(error);
      console.error('Error info:', info, error.message);
    }
  }

  // Reload the page to fix chunk loading issues
  handleChunkLoadError = (error) => {
    console.warn(
      'Chunk loading failed or other error detected, reloading the page...',
    );
    window.location.reload(true);
  };

  render() {
    // Return children directly
    return this.props.children;
  }
}

export default ErrorBoundary;
