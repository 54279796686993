export const CONTROL_VARIANT = 'Control';

export const FeatureFlags = {
  FAKE_DOOR: 'trueprofile-fake-door',
  PRICING_EXPERIMENT: 'experiment-3-month-pricing-package',
  TRUSTPILOT: 'trustpilot-feature-flag',
  PAY_AS_YOU_GO_PAYWALL_MARKETING_CAMPAIGN:
    'pay-as-you-go-paywall-marketing-campaign',
  V_1_3_REFERRAL_UI: 'v-1-3-referral-ui',
  V_1_4_FEATURES: 'v-1-4-features',
  V_1_5_TRUEPROFILE_MVP: 'v-1-5-trueprofile-mvp',
};

export const CustomConversionEvents = {
  CREATE_NEW_ACCOUNT: 'create-new-account',
  RESUME_BUILDER_NEXT_STEP: 'resume-builder-next-step',
  COMPLETE_PURCHASE: 'complete-purchase',
  IMPORT_FROM_LINKED_IN_SELECTED: 'import-from-linked-in-selected',
  IMPORT_FROM_FILE_SELECTED: 'import-from-file-selected',
  MANUAL_ENTRY_SELECTED: 'manual-entry-selected',
  GENERATE_FIRST_RESUME: 'generate-first-resume',
};
