import Cookies from 'js-cookie';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

import { parse } from '@tinyhttp/content-disposition';

import {
  ENV_LOCAL,
  ENV_PREVIEW,
  getDomain,
  getEnvSetting,
} from './envSettings';

export const concatenate = (separator, ...args) => {
  return args
    .filter((item) => !!item)
    .join(separator)
    .trim();
};

export const triggerPdfDownload = (fileName, blobUrl) => {
  const link = document.createElement('a');
  link.setAttribute('download', fileName);
  link.setAttribute('href', blobUrl);
  link.click();
};

export const sortByDates = (a, b) => {
  const parseDate = (date, isCurrentPosition) => {
    if (isCurrentPosition) return 'Present';
    if (!date) return null;
    return DateTime.fromFormat(date, 'MM/yy');
  };

  const start_time_1 = parseDate(a.start_date);
  const start_time_2 = parseDate(b.start_date);
  const end_time_1 = parseDate(
    a.end_date,
    a.current_position || a.currently_attending,
  );
  const end_time_2 = parseDate(
    b.end_date,
    b.current_position || b.currently_attending,
  );

  const compareDates = (d1, d2) => {
    if (d1 === 'Present') return -1;
    if (d2 === 'Present') return 1;
    if (!d1) return 1;
    if (!d2) return -1;
    if (d1 < d2) return 1;
    if (d1 > d2) return -1;
    return 0;
  };

  // Compare end dates first
  const endComparison = compareDates(end_time_1, end_time_2);
  if (endComparison !== 0) return endComparison;

  // If end dates are equal, compare start dates
  return compareDates(start_time_1, start_time_2);
};

export const formatDate = (inputDate, format = 'MM/yyyy') => {
  return inputDate ? DateTime.fromISO(inputDate).toFormat(format) : null;
};

export const formatFromIso = (input, format = DateTime.DATE_MED) => {
  return DateTime.fromISO(input).toLocaleString(format, { locale: 'en-US' });
};

export const getContentFilename = (content) => {
  try {
    const disposition = parse(content);
    return disposition.parameters.filename;
  } catch {
    return null;
  }
};

export const startResumeBuilderFlow = (dispatch, view = 'JOB_DESCRIPTION') => {
  dispatch({
    type: 'resumeBuilderWizard/updateBuilderStepView',
    payload: { view },
  });
  dispatch({ type: 'resumeBuilderWizard/goToStep', payload: { step: 1 } });
  dispatch({ type: 'resumeBuilderWizard/setInDemandSkills', payload: [] });
  dispatch({
    type: 'resumeBuilderWizard/resetResumeData',
    payload: { view: null, inProgress: false },
  });
  dispatch({ type: 'segmentationData/resetData' });
};

export const formatCurrency = (value) => {
  const formatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(Number(value));
};

export const staticPage = (page) => {
  return (getEnvSetting('STATIC_SITE_URL') || '') + page;
};

export const handleNameInput = (e) => {
  const value = e.target?.value || '';
  const allowedCharacters = /^[A-Za-z'\-\s]*$/;
  if (!allowedCharacters.test(value)) {
    e.target.value = value.replace(/[^A-Za-z\s-']+/, '');
  }
};

export const formatDatesAsString = (startDate, endDate, current) => {
  if (current) {
    return startDate ? `${startDate} - Present` : 'Present';
  } else {
    if (startDate && endDate) {
      return `${startDate} - ${endDate}`;
    } else {
      return startDate || endDate || '';
    }
  }
};

export const getFieldValue = (value, checked, type) => {
  const onlySpaces = /^\s*$/.test(value);
  switch (type) {
    case 'checkbox':
      return checked;
    default:
      return onlySpaces ? null : value;
  }
};

export const isEmptyObject = (obj) => {
  return (
    !obj ||
    !Object.keys(obj).length ||
    Object.values(obj).every((value) => !value)
  );
};

export const getLaunchDarklySessionKey = (user, regenerateKey) => {
  const needSimpleOptions = ENV_LOCAL || ENV_PREVIEW;
  // Creating new user from same device require deleting old user cookie
  if (regenerateKey) {
    // Delete old cookie
    Cookies.remove('LD_CONTEXT_KEY');

    const LD_CONTEXT_KEY = uuidv4();
    const OPTIONS = needSimpleOptions
      ? {}
      : { path: '/', domain: getDomain(), secure: true, sameSite: 'None' };

    Cookies.set('LD_CONTEXT_KEY', LD_CONTEXT_KEY, OPTIONS);
    Cookies.set('multiple_accounts_on_same_device', 'true', OPTIONS);
    return LD_CONTEXT_KEY;
  }

  // No user
  if (!user || !user.mtr) {
    const cookie = Cookies.get('LD_CONTEXT_KEY');
    const LD_CONTEXT_KEY = cookie || uuidv4();

    if (!cookie) {
      const OPTIONS = needSimpleOptions
        ? {}
        : { path: '/', domain: getDomain(), secure: true, sameSite: 'None' };
      Cookies.set('LD_CONTEXT_KEY', LD_CONTEXT_KEY, OPTIONS);
    }

    console.info('no user returning cookie', LD_CONTEXT_KEY);
    return LD_CONTEXT_KEY;
  }

  //RETURNING USER
  if (user.mtr) {
    const STORED_LD_CONTEXT_KEY = Cookies.get('LD_CONTEXT_KEY');

    // Same device
    if (user.mtr === STORED_LD_CONTEXT_KEY) {
      console.info('Returning user with same device');
      return user.mtr;
    } else {
      // New device or refreshed cache
      console.info('Returning user with new device');

      // Overrides the LD_CONTEXT_KEY with the MTR value from the DB
      const OPTIONS = needSimpleOptions
        ? {}
        : { path: '/', domain: getDomain(), secure: true, sameSite: 'None' };
      Cookies.set('LD_CONTEXT_KEY', user.mtr, OPTIONS);

      // Reloads the page to ensure consistency
      window.location.reload();
      return user.mtr;
    }
  }
};

export const computeJobTitle = (jobOpportunity, suffix = '.') => {
  if (!jobOpportunity) {
    return '';
  }

  return `${concatenate(' @ ', jobOpportunity.title, jobOpportunity.company)}${suffix}`;
};

export const triggerNotification = (setter, message, variant) => {
  setter({
    show: true,
    message,
    variant,
  });
  setTimeout(() => {
    setter({
      show: false,
      message: '',
      variant: '',
    });
  }, 3000);
};
