import { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as ClipboardGraphIcon } from '../../assets/icons/koi/clipboard-graph-icon.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/koi/home-icon.svg';
import { ReactComponent as TrueprofileIcon } from '../../assets/icons/koi/trueprofile-icon.svg';
import { FeatureFlags } from '../../constants/featureFlags';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import usePricingExperimentFlag from '../../hooks/usePricingExperimentFlag';
import useTrueprofileEnabled from '../../hooks/useTrueprofileEnabled';
import CreateDocumentButton from '../../routes/Dashboard/CreateDocumentButton';
import SplashScreen from '../../routes/Trueprofile/SplashScreen';
import AppContext from '../../store/AppContext';
import Tracking from '../../utils/tracking';
import { staticPage } from '../../utils/util';

import TruepathLogo from './TruepathLogo';

import './MobileHeader.scss';

export default function MobileHeader({ children, superUser }) {
  const [state] = useContext(AppContext);
  const { currentUser } = state;
  const location = useLocation();
  const { pathname } = location;
  const [openSplashScreen, setOpenSplashScreen] = useState(false);
  const PRICING_EXPERIMENT_ENABLED = usePricingExperimentFlag();

  const isPublicProfilePage = location.pathname.includes('/u/');
  const isFTUEPreviewPage = location.pathname.includes('/view-resume');
  const isResumeBuilderPage = location.pathname.includes('/resume-builder');

  const showReferralUI = useFeatureFlag(FeatureFlags.V_1_3_REFERRAL_UI, false);
  const fakeDoorEnabled = useFeatureFlag(FeatureFlags.FAKE_DOOR, false);
  const trueprofileEnabled = useTrueprofileEnabled();
  const hideLinks = isFTUEPreviewPage && PRICING_EXPERIMENT_ENABLED;

  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClick = (trackEventName) => {
    if (typeof trackEventName === 'string') {
      Tracking.trackUserClicked({
        widget_name: trackEventName,
        from_view: 'Dashboard',
        view_type: 'Mobile',
      });
    }
    setShowMenu(false);
  };

  const checkIsSelectedPath = (path) => path === pathname;

  useEffect(() => {
    document.body.classList[showMenu ? 'add' : 'remove']('__body-fixed-mobile');
  }, [showMenu]);

  const renderPublicMenu = () => {
    let topNavItems;
    if (isPublicProfilePage && trueprofileEnabled) {
      topNavItems = (
        <>
          <a
            className="mobile-nav-item"
            href={staticPage('/trueprofile')}
            target="_blank"
            rel="noreferrer"
          >
            Trueprofile
          </a>
          <a className="mobile-nav-item" href="/resume-builder">
            Build your FREE Trueprofile
          </a>
        </>
      );
    } else if (isResumeBuilderPage) {
      topNavItems = (
        <>
          <a
            className="mobile-nav-item"
            href={staticPage('/resume')}
            target="_blank"
            onClick={handleMenuClick}
            rel="noreferrer"
          >
            Resume
          </a>

          <a
            className="mobile-nav-item"
            href={staticPage('/pricing')}
            target="_blank"
            onClick={handleMenuClick}
            rel="noreferrer"
          >
            Pricing
          </a>
        </>
      );
    } else {
      topNavItems = (
        <>
          {trueprofileEnabled && (
            <a
              className="mobile-nav-item"
              href={staticPage('/trueprofile')}
              target="_blank"
              rel="noreferrer"
            >
              Trueprofile <span className="new-badge">NEW</span>
            </a>
          )}

          <a
            className="mobile-nav-item"
            href={staticPage('/resume')}
            target="_blank"
            onClick={handleMenuClick}
            rel="noreferrer"
          >
            Resume
          </a>

          <a
            className="mobile-nav-item"
            href={staticPage('/pricing')}
            target="_blank"
            onClick={handleMenuClick}
            rel="noreferrer"
          >
            Pricing
          </a>
          <a className="mobile-nav-item" href={'/resume-builder'}>
            Try it now
          </a>
        </>
      );
    }

    if (hideLinks) {
      topNavItems = <></>;
    }

    return (
      <>
        <div className="top-items">{topNavItems}</div>

        <div className="bottom-items">
          <div className="section-item">My account</div>
          <Link
            className="mobile-nav-item"
            to="/login"
            onClick={handleMenuClick}
          >
            Log In
          </Link>
        </div>
      </>
    );
  };

  const renderPrivateMenu = () => {
    if (superUser) {
      return (
        <div className="top-items">
          <Link className="mobile-nav-item" to="/logout">
            Log out
          </Link>
        </div>
      );
    }

    return (
      <>
        <div className="top-items">
          <>
            <div className="section-item">My Truepath.ai dashboard</div>

            <Link
              className={cn('mobile-nav-item', {
                active: checkIsSelectedPath('/dashboard'),
              })}
              to={
                trueprofileEnabled ? '/dashboard' : '/dashboard?tab=documents'
              }
              onClick={handleMenuClick}
            >
              {trueprofileEnabled && <HomeIcon />}
              Home
            </Link>

            {trueprofileEnabled ? (
              <Link
                className={cn('mobile-nav-item', {
                  active: checkIsSelectedPath('/profile'),
                })}
                to="/profile"
                onClick={() => handleMenuClick('Profile')}
              >
                <TrueprofileIcon />
                Profile
              </Link>
            ) : (
              fakeDoorEnabled && (
                <span
                  className="mobile-nav-item"
                  onClick={() => {
                    setOpenSplashScreen(true);
                    setShowMenu(false);
                  }}
                >
                  Trueprofile
                  <span className="new-badge">NEW</span>
                </span>
              )
            )}

            <Link
              className={cn('mobile-nav-item', {
                active: checkIsSelectedPath('/assessment-results'),
              })}
              to={
                trueprofileEnabled
                  ? '/assessment-results'
                  : '/dashboard?tab=assessment'
              }
              onClick={handleMenuClick}
            >
              {trueprofileEnabled && <ClipboardGraphIcon />}
              Assessment results
            </Link>

            {trueprofileEnabled && (
              <CreateDocumentButton
                showIcon
                onClick={() => setShowMenu(false)}
              />
            )}

            {!trueprofileEnabled && (
              <Link
                className="mobile-nav-item"
                to="/dashboard?tab=templates"
                onClick={handleMenuClick}
              >
                Templates
              </Link>
            )}
          </>
        </div>

        <div className="bottom-items">
          <div className="section-item">Account settings</div>
          <Link
            className={cn('mobile-nav-item', {
              active: checkIsSelectedPath('/account'),
            })}
            to={trueprofileEnabled ? '/account' : '/settings?tab=account'}
            onClick={handleMenuClick}
          >
            Account
          </Link>
          <Link
            className={cn('mobile-nav-item', {
              active: checkIsSelectedPath('/subscription'),
            })}
            to={
              trueprofileEnabled
                ? '/subscription'
                : '/settings?tab=subscription'
            }
            onClick={handleMenuClick}
          >
            Subscription
          </Link>
          <Link
            className={cn('mobile-nav-item', {
              active: checkIsSelectedPath('/payments'),
            })}
            to={trueprofileEnabled ? '/payments' : '/settings?tab=payment'}
            onClick={handleMenuClick}
          >
            Payment
          </Link>
          {showReferralUI && currentUser?.referrer_count < 3 && (
            <Link
              className={cn('mobile-nav-item', {
                active: checkIsSelectedPath('/invite'),
              })}
              to="/invite"
              onClick={handleMenuClick}
            >
              Invite friends
            </Link>
          )}
          <Link
            className="mobile-nav-item"
            to="/logout"
            onClick={handleMenuClick}
          >
            Logout
          </Link>
        </div>
      </>
    );
  };

  return (
    <div className="__mobile-header">
      <div className="mobile-header-title">
        <div className="flex">
          <TruepathLogo />
          {superUser && window.location.pathname.includes('/admin') && (
            <p className="admin-label">ADMIN</p>
          )}
        </div>
        {!superUser && children}

        <div
          className="hamburger-menu"
          role="button"
          onClick={() => {
            setShowMenu(true);
            setOpenSplashScreen(false);
          }}
        >
          <div className="lines" />
          <div className="lines" />
          <div className="lines" />
        </div>
      </div>

      {showMenu && (
        <div className="drawer">
          <div className="drawer-header">
            <TruepathLogo />
            <button
              className="close-snippet"
              onClick={() => setShowMenu(false)}
            >
              ✕
            </button>
          </div>

          <div className="drawer-items">
            {!currentUser?.registered
              ? renderPublicMenu()
              : renderPrivateMenu()}
          </div>
        </div>
      )}
      {openSplashScreen && (
        <SplashScreen
          onCloseSplashScreen={(event) => {
            setOpenSplashScreen(false);
            if (event !== 'AVOID_LOG') {
              Tracking.trackUserClicked({
                widget_name: 'trueprofile-splashscreen-close',
              });
            }
          }}
        />
      )}
    </div>
  );
}
