import { useMemo, useState } from 'react';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/koi/arrow-left.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/koi/checkmark.svg';
import { ReactComponent as ChevronDown } from '../../assets/icons/koi/chevron-down.svg';
import { ReactComponent as InvalidIcon } from '../../assets/icons/koi/invalid-icon.svg';
import { ReactComponent as ValidIcon } from '../../assets/icons/koi/valid-icon.svg';
import { ReactComponent as PoweredByStripe } from '../../assets/pricing/powered_stripe_black.svg';
import TruepathInput from '../../common/TruepathInput/TruepathInput';
import { STRIPE_STYLE } from '../../constants/payment';
import usePaywallDiscountModal from '../../hooks/usePaywallDiscountModal';
import usePaywallMonthDiscount from '../../hooks/usePaywallMonthDiscount';
import usePricingExperimentFlag from '../../hooks/usePricingExperimentFlag';
import { useWindowSize } from '../../hooks/useWindowSize';
import KoiButton from '../../layout/koi/KoiButton';
import { addDaysToDate } from '../../utils/dates';
import { getDiscountedPrice } from '../../utils/formatter';

import { MONTHLY_TIERS } from './constants';

export function SelectedPlanDetails({
  coupon,
  currentUser,
  features,
  selectedPlan,
}) {
  const [displayDetails, setDisplayDetails] = useState(false);
  const PRICING_EXPERIMENT_ENABLED = usePricingExperimentFlag();
  const { isMobile } = useWindowSize();
  const { utmDiscount, showPaywallDiscountModal } = usePaywallDiscountModal();
  const discountDisclaimer = `You will automatically be charged the subscription
  fee of ${getDiscountedPrice(selectedPlan.price, utmDiscount)} today, and then
  charged $24.90 every month until you cancel.`;
  const hideFreeTrial = selectedPlan.oneTimePayment || !selectedPlan.free_trial;
  const currentMonthData = useMemo(() => {
    const plans = Object.values(MONTHLY_TIERS);
    const plan = plans.find((plan) => plan.id === selectedPlan.id);
    return plan || MONTHLY_TIERS['pay-as-you-go'];
  }, [selectedPlan?.id]);

  const isSubscribed = currentUser?.subscribed;

  const { showPaywallMonthDiscount } = usePaywallMonthDiscount();
  const useMonthDiscount = !isSubscribed && showPaywallMonthDiscount;

  const renderPrice = (price) => {
    if (coupon.percent_off) {
      return getDiscountedPrice(price, coupon.percent_off);
    }

    return price;
  };

  const renderControlVariant = () => (
    <>
      <div className="top-content">
        <div className="selected-plan-details">
          <p className="selected-plan-label">Selected plan</p>
          <div className="selected-plan-controls">
            <p className="main-title">{selectedPlan.title}</p>
            <ChevronDown
              className={classNames({ reversed: displayDetails })}
              onClick={() => {
                setDisplayDetails(!displayDetails);
              }}
            />
          </div>
          <p className="main-subtitle">{selectedPlan.info}</p>

          <div
            className={classNames('selected-plan-features', {
              hidden: !displayDetails,
            })}
          >
            <p>Includes:</p>
            {features.map((feature) => (
              <div className="plan-feature-item" key={feature}>
                <CheckmarkIcon />
                <p>{feature}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={classNames('footer-info', {
          'footer-info-variant-mobile': PRICING_EXPERIMENT_ENABLED && isMobile,
        })}
      >
        {selectedPlan.free_trial && (
          <div className="flex-item">
            <p className="main-title">Due today</p>{' '}
            <p className="main-title green-label">$0</p>
          </div>
        )}
        <div className="flex-item">
          {selectedPlan.free_trial ? (
            <>
              <p>Billed on {addDaysToDate(7)}</p>
              <div className="display-flex">
                {coupon.percent_off && (
                  <p className="billed-on-price coupon-discount">
                    {selectedPlan.fullPrice}
                  </p>
                )}
                <p className="billed-on-price">
                  {coupon.percent_off
                    ? getDiscountedPrice(
                        selectedPlan.fullPrice,
                        coupon.percent_off,
                      )
                    : selectedPlan.fullPrice}
                </p>
              </div>
            </>
          ) : (
            <>
              <p className="main-title">Due today</p>
              <div className="display-flex">
                {coupon.percent_off && (
                  <p className="main-title coupon-discount">
                    {selectedPlan.fullPrice}
                  </p>
                )}

                <p className="main-title green-label">
                  {coupon.percent_off
                    ? getDiscountedPrice(
                        selectedPlan.fullPrice,
                        coupon.percent_off,
                      )
                    : selectedPlan.fullPrice}
                </p>
              </div>
            </>
          )}
        </div>

        <p className="disclaimer">
          {showPaywallDiscountModal
            ? discountDisclaimer
            : selectedPlan.disclaimer}
        </p>
      </div>
    </>
  );

  const renderExperimentVariant = () => (
    <>
      <div className="top-content">
        <div className="selected-plan-details selected-plan-details-variant">
          <p className="selected-plan-label-variant">Order summary</p>
          <div className="pricing-plan">
            <div className="plan-header">
              <h2 className="plan-header-title">{currentMonthData.title}</h2>
              <span className="price">
                {currentMonthData.price}
                {currentMonthData.onePayment ? '/doc' : '/mo'}
              </span>
            </div>
            {!hideFreeTrial && (
              <button className="free-trial-btn">7-day free trial</button>
            )}

            <hr className="payment-divider" />
            <div className="total-due">
              <p className="plan-header-title">Total due today</p>
              <span className="price-today display-flex">
                {coupon.percent_off && hideFreeTrial && (
                  <p className="coupon-discount">
                    {hideFreeTrial ? selectedPlan.fullPrice : '$0.00'}
                  </p>
                )}
                {hideFreeTrial ? renderPrice(selectedPlan.fullPrice) : '$0.00'}
              </span>
            </div>

            {!hideFreeTrial && (
              <>
                <div className="billing-info">
                  <p className="mb-0">
                    Charged on {addDaysToDate(7)}, after trial ends
                  </p>
                  <span className="future-price display-flex">
                    {coupon.percent_off && (
                      <p className="coupon-discount">
                        {selectedPlan.fullPrice}
                      </p>
                    )}
                    {renderPrice(currentMonthData.fullPrice)}
                  </span>
                </div>

                <hr className="payment-divider" />
                <div className="free-trial-terms">
                  <h3 className="plan-header-title">Free trial terms</h3>
                  <ul>
                    <li>Billing automatically starts after free trial ends</li>
                    <li>
                      Cancel anytime before {addDaysToDate(7)} to avoid getting
                      charged
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames('footer-info', {
          'footer-info-variant-mobile': PRICING_EXPERIMENT_ENABLED && isMobile,
        })}
      >
        {!isMobile && (
          <p className="disclaimer">
            {showPaywallDiscountModal
              ? discountDisclaimer
              : selectedPlan.disclaimer}
          </p>
        )}
      </div>
    </>
  );

  return PRICING_EXPERIMENT_ENABLED && !useMonthDiscount
    ? renderExperimentVariant()
    : renderControlVariant();
}

export function PaymentForm({
  error,
  fetchPromoCode,
  processingPayment,
  promoCodeStatus,
  promoCodeValue,
  purchaseSuccess,
  recaptchaRef,
  reCaptchaSiteKey,
  selectedPlan,
  handlePromoCodeInput,
  handleSubmit,
  paymentRequest,
  onHide,
  onBack,
}) {
  const PRICING_EXPERIMENT_ENABLED = usePricingExperimentFlag();
  const { isMobile } = useWindowSize();
  return (
    <>
      <div
        className="close-icon"
        onClick={() => {
          onHide();
        }}
      >
        <CloseIcon />
      </div>
      <div className="arrow-icon-container" onClick={onBack}>
        <ArrowLeft />
      </div>

      <div
        className={classNames('choose-plan-label show', {
          'choose-plan-label-variant': PRICING_EXPERIMENT_ENABLED,
        })}
      >
        {PRICING_EXPERIMENT_ENABLED ? 'Payment details' : 'Payment methods'}
      </div>

      {paymentRequest && (
        <div className="third-party-payments">
          <PaymentRequestButtonElement options={{ paymentRequest }} />
          <div className="separator">
            <span>Or pay with card</span>
          </div>
        </div>
      )}

      <Form onSubmit={handleSubmit} className="paywall-form">
        <div className="top-content">
          <div className="card-number">
            <div className="stripe-label">Card number</div>
            <div className="input">
              <CardNumberElement
                options={{ style: STRIPE_STYLE, showIcon: true }}
              />
            </div>
          </div>

          <div className="date-cvc-container">
            <div className="expiry-date">
              <div className="stripe-label">Expiration date</div>
              <div className="input">
                <CardExpiryElement options={{ style: STRIPE_STYLE }} />
              </div>
            </div>
            <div className="cvc">
              <div className="stripe-label">Security code</div>
              <div className="input">
                <CardCvcElement options={{ style: STRIPE_STYLE }} />
              </div>
            </div>
          </div>
          <a
            className="powered-by-stripe"
            rel="__noreferrer"
            href="https://stripe.com"
          >
            <PoweredByStripe />
          </a>

          <hr />

          <div className="input promo-code-wrapper">
            <div className="promo-code-input">
              {PRICING_EXPERIMENT_ENABLED && (
                <p className="promo-code-label">Promo code</p>
              )}
              <TruepathInput
                label={!PRICING_EXPERIMENT_ENABLED ? 'Enter promo code' : ''}
                className={
                  promoCodeStatus === 'ERROR' ? 'invalid-code-input' : ''
                }
                value={promoCodeValue}
                onChange={handlePromoCodeInput}
              />

              <div
                className={classNames('promo-code-icon', {
                  'promo-code-icon-variant': PRICING_EXPERIMENT_ENABLED,
                })}
              >
                {promoCodeStatus === 'SUCCESS' && <ValidIcon />}
                {promoCodeStatus === 'ERROR' && <InvalidIcon />}
              </div>
            </div>

            <KoiButton
              className="apply-btn ml-10"
              variant="secondary"
              onClick={fetchPromoCode}
              disabled={!promoCodeValue}
            >
              {promoCodeStatus === 'LOADING' ? <Spinner /> : 'Apply'}
            </KoiButton>
          </div>
          {promoCodeStatus === 'ERROR' && (
            <p className="invalid-code">Invalid code.</p>
          )}
        </div>

        <div className="bottom-container">
          <div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={reCaptchaSiteKey}
              size="invisible"
            />
          </div>
        </div>
        <div
          className={
            PRICING_EXPERIMENT_ENABLED && isMobile
              ? 'continue-btn-wrapper-variant'
              : 'continue-btn-wrapper'
          }
        >
          <KoiButton
            type="submit"
            className={
              PRICING_EXPERIMENT_ENABLED && isMobile
                ? 'continue-btn-variant'
                : 'continue-btn'
            }
            variant="primary"
            disabled={!selectedPlan.id || purchaseSuccess || error}
          >
            {purchaseSuccess ? (
              'Payment successful!'
            ) : processingPayment ? (
              <Spinner />
            ) : (
              'Confirm'
            )}
          </KoiButton>
        </div>
      </Form>
    </>
  );
}
