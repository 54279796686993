import { useCallback, useContext, useEffect } from 'react';

import AppContext from '../store/AppContext';
import { api } from '../utils/api';

/*
stream: {
  id: ${type}_${id}
  status: "start", "complete", "streaming", "error",
  type: "resume", "cover_letter", "timeline",
}
*/

export default function useStreaming() {
  const [state, dispatch] = useContext(AppContext);

  // const { resume, cover_letter, timeline } = state.streams;
  const { resume, cover_letter } = state.streams;

  const streamProgress = useCallback(
    (item, progress) => {
      const { response } = progress.event.currentTarget;
      const allResponses = response.split('\n\n');
      const lastResponse = JSON.parse(
        allResponses[allResponses.length - 2].substring(6),
      );

      dispatch({
        type: 'streams/update',
        payload: {
          id: item.id,
          type: item.type,
          content: lastResponse['partial-response'],
        },
      });
    },
    [dispatch],
  );

  const handleRequest = useCallback(
    async (item) => {
      try {
        dispatch({
          type: 'streams/update',
          payload: { id: item.id, type: item.type, status: 'streaming' },
        });

        await api.put(
          `/job_opportunities/${item.id}/generate_${item.type}`,
          {},
          {
            onDownloadProgress: (progress) => streamProgress(item, progress),
          },
        );

        dispatch({
          type: 'streams/update',
          payload: { id: item.id, type: item.type, status: 'complete' },
        });
      } catch (error) {
        console.log('error', error);
        dispatch({
          type: 'streams/update',
          payload: { id: item.id, type: item.type, status: 'error' },
        });
      }
    },
    [dispatch, streamProgress],
  );

  useEffect(() => {
    const items = Object.values(cover_letter);

    items.forEach(async (item) => {
      if (item.status === 'start') {
        handleRequest(item);
      }
    });
  }, [handleRequest, cover_letter]);

  useEffect(() => {
    const items = Object.values(resume);

    items.forEach(async (item) => {
      if (item.status === 'start') {
        handleRequest(item);
      }
    });
  }, [handleRequest, resume]);
}
