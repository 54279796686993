import { isNil } from 'lodash';
import { useLocation, useSearchParams } from 'react-router-dom';

const usePaywallMonthDiscount = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const utmSource = searchParams.get('utm_source');
  const utmMedium = searchParams.get('utm_medium');
  const utmContent = searchParams.get('utm_content');
  const utmStripeId = searchParams.get('utm_stripe_id');
  const allowedPaths =
    location.pathname.includes('dashboard') ||
    location.pathname.includes('view-resume');

  const showPaywallMonthDiscount =
    allowedPaths &&
    utmSource === 'brevo' &&
    utmMedium === 'email' &&
    utmContent === 'freemonthspecial' &&
    !isNil(utmStripeId);

  return {
    utmSource,
    utmMedium,
    utmContent,
    utmStripeId,
    showPaywallMonthDiscount,
  };
};

export default usePaywallMonthDiscount;
