import './TruepathLogo.scss';

import { useAppContext } from '../../store/AppContext';
import { getEnvSetting } from '../../utils/envSettings';

export default function TruepathLogo() {
  const { appState } = useAppContext();
  const { loggedIn } = appState;

  return (
    <div className="__truepath-logo">
      <a
        className="title"
        href={getEnvSetting('STATIC_SITE_URL') || '/static'}
        target={loggedIn ? '_blank' : '_self'}
        rel="noreferrer"
      >
        <strong>true</strong>path.ai
      </a>
    </div>
  );
}
