import Cookies from 'js-cookie';

import { RudderAnalytics } from '@rudderstack/analytics-js';

import { envSettingTrue, getEnvSetting } from './envSettings';

export const TRACKING_ENABLED = envSettingTrue('ENABLE_TRACKING_V2');
const analytics = new RudderAnalytics();
export function parseAbTests() {
  try {
    return JSON.parse(Cookies.get('talent__ab_tests'));
  } catch {
    return {};
  }
}

export function addAbTest(identifier, value) {
  Cookies.set(
    'talent__ab_tests',
    JSON.stringify({ ...parseAbTests(), [identifier]: value }),
  );
}

export function trackEvent(event, properties) {
  if (!TRACKING_ENABLED) {
    return;
  }

  const tests = parseAbTests();
  const normalizedTests = {};

  Object.entries(tests).forEach(([key, value]) => {
    normalizedTests[`AB Test: ${key}`] = value.variant;
  });

  properties = { ...properties, ...normalizedTests };

  analytics.track(event, properties, () => {
    console.log('Track event successfully submitted to the RudderStack SDK.');
  });
}

const initialize = () => {
  if (!TRACKING_ENABLED) {
    return;
  }

  analytics.load(
    getEnvSetting('RUDDER_STACK_WRITE_KEY'),
    getEnvSetting('RUDDER_STACK_URL'),
  );
  //TagManager.initialize({ gtmId: getEnvSetting('GTM_ID') });
};

const trackPageViewed = (properties) => {
  trackEvent('page_viewed', properties);
};

const identify = (unique_id) => {
  TRACKING_ENABLED && analytics.identify(unique_id);
};

const trackUserProfileData = (properties) => {
  //TRACKING_ENABLED && mixpanel.people.set(properties);
};

const TrackingV2 = {
  initialize,
  identify,
  trackUserProfileData,

  // Mixpanel-only events. The number of events is kept small and generic
  trackWidgetViewed: (properties) => trackEvent('widget_viewed', properties),
  trackUserClicked: (properties) => trackEvent('user_clicked', properties),
  trackQuestionAnswered: (properties) =>
    trackEvent('question_answered', properties),
  trackConditionMet: (properties) => trackEvent('condition_met', properties),
  //reset: () => TRACKING_ENABLED && mixpanel.reset(),
  trackPageScrolled: (properties) => trackEvent('page_scrolled', properties),
  trackLatency: (properties) => trackEvent('latency_check', properties),
  trackPurchase: (properties) => trackEvent('purchase_completed', properties),
  trackLDExperiment: (properties) =>
    trackEvent('experiment_started', properties),
  trackSignedUp: (properties) => trackEvent('sign_up', properties),
  addAbTest: (identifier, value) => addAbTest(identifier, value),

  trackPageViewed,
};

export default TrackingV2;
