import { useContext } from 'react';
import classNames from 'classnames';
import { NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as AccountIcon } from '../../assets/icons/koi/account-icon.svg';
import { FeatureFlags } from '../../constants/featureFlags';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import AppContext from '../../store/AppContext';

import './AccountMenu.scss';

export default function AccountMenu() {
  const location = useLocation();
  const [state] = useContext(AppContext);
  const { currentUser } = state;

  const showReferralUI = useFeatureFlag(FeatureFlags.V_1_3_REFERRAL_UI, false);

  return (
    <NavDropdown className="__account-menu" title={<AccountIcon />}>
      <NavDropdown.Item
        as={Link}
        to="/settings?tab=account"
        className={classNames({
          active: location.pathname.includes('/settings'),
        })}
      >
        Account settings
      </NavDropdown.Item>
      {currentUser?.referrer_count < 3 && showReferralUI && (
        <NavDropdown.Item
          as={Link}
          to="/invite"
          className={classNames({
            active: location.pathname.includes('/invite'),
          })}
        >
          Invite Friends
        </NavDropdown.Item>
      )}
      <NavDropdown.Item as={Link} to="/logout">
        Log out
      </NavDropdown.Item>
    </NavDropdown>
  );
}
