import { useFlags } from 'launchdarkly-react-client-sdk';

export default function useFeatureFlag(key, defaultValue) {
  // All feature flags from Launch Darkly
  const flags = useFlags();

  if (!key) {
    return defaultValue;
  }

  // Return single feature flag value
  if (typeof key === 'string') {
    return flags?.[key] || defaultValue;
  }

  // Return array of feature flag values
  if (Array.isArray(key)) {
    return key.map((item) => flags?.[item] || defaultValue);
  }

  return defaultValue;
}
