import { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';

const initialState = {
  currentStep: 0,
  currentView: 'SELECT_DOCUMENTS',
  resumeSource: null,
  selectedDocuments: {
    resume: false,
    coverLetter: false,
  },
  job: null,
  selectedSkills: [],
};

function reducer(draft, action) {
  switch (action.type) {
    case 'documentBuilder/goToStep':
      draft.currentStep = action.payload;
      break;

    case 'documentBuilder/previousStep':
      draft.currentStep = draft.currentStep - 1;
      break;

    case 'documentBuilder/nextStep':
      draft.currentStep = draft.currentStep + 1;
      break;

    case 'documentBuilder/updateView':
      draft.currentView = action.payload;
      break;

    case 'documentBuilder/setSelectedDocuments':
      draft.selectedDocuments = action.payload;
      break;

    case 'documentBuilder/setResumeSource':
      draft.resumeSource = action.payload;
      break;

    case 'documentBuilder/setJob':
      draft.job = action.payload;
      break;

    case 'documentBuilder/setSelectedSkills':
      draft.selectedSkills = action.payload;
      break;

    case 'documentBuilder/reset':
      return initialState;

    default:
      console.warn('Non-existing action type:', action.type);
  }
}

// Create the context
const DocumentBuilderContext = createContext(null);

// Create a custom provider component
export function DocumentBuilderProvider({ children }) {
  const [dbState, dbDispatch] = useImmerReducer(reducer, initialState);

  return (
    <DocumentBuilderContext.Provider value={{ dbState, dbDispatch }}>
      {children}
    </DocumentBuilderContext.Provider>
  );
}

// Custom hook to use the DocumentBuilderContext
export function useDocumentBuilderContext() {
  const { dbState, dbDispatch } = useContext(DocumentBuilderContext);
  return { dbState, dbDispatch };
}
