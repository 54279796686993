import { useNavigate } from 'react-router-dom';

import { useAppContext } from '../store/AppContext';
import { useDocumentBuilderContext } from '../store/DocumentBuilderContext';

import useV4Features from './useV4Features';

export default function useStartDocumentBuilder() {
  const { appDispatch } = useAppContext();
  const { dbDispatch } = useDocumentBuilderContext();

  const V_4_FEATURES_ENABLED = useV4Features();

  const navigate = useNavigate();

  function startDocumentBuilder() {
    if (V_4_FEATURES_ENABLED) {
      dbDispatch({ type: 'documentBuilder/reset' });
      appDispatch({ type: 'currentResume/set', payload: null });
      appDispatch({ type: 'currentCoverLetter/set', payload: null });
    } else {
      appDispatch({ type: 'documentBuilder/resetData' });
    }

    navigate('/document-builder');
  }

  return startDocumentBuilder;
}
