import { useContext } from 'react';
import { isNil } from 'lodash';
import { Navigate, Outlet } from 'react-router-dom';

import AppContext from '../store/AppContext';

export default function PublicRoutes({ onlyGuests = false }) {
  const [state] = useContext(AppContext);
  const { loggedIn } = state;

  if (isNil(loggedIn)) {
    return null;
  }

  // Check if route is only for guests users
  if (onlyGuests && loggedIn === true) {
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet />;
}
