import classNames from 'classnames';

import './KoiTitle.scss';

export default function KoiTitle({ as = 'h2', className, children, ...props }) {
  const Component = as;

  return (
    <Component className={classNames('__koi-title', className)} {...props}>
      {children}
    </Component>
  );
}

export function Highlight({ children, whiteHighlight }) {
  return (
    <div className="__highlight">
      <div
        className={
          whiteHighlight ? '__highlight-color-white' : '__highlight-color'
        }
      />
      {children}
    </div>
  );
}

export function HighlightV2({ children }) {
  return (
    <div className="__highlight-section">
      <span className="__highlight-text">{children}</span>
    </div>
  );
}
