import axios from 'axios';
import Cookies from 'js-cookie';

import Bugsnag from '@bugsnag/js';

import { getEnvSetting } from './envSettings';

const apiURL = getEnvSetting('API_URL');

export let api = axios.create({
  baseURL: apiURL,
  headers: {
    Authorization: `Bearer ${Cookies.get('talent__api_access_token')}`,
  },
});

export function resetAccessToken(token) {
  //  instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;
  //! it seems there is a bug that even the default axios instance will show the headers!!
  // strofeApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  api = axios.create({
    baseURL: apiURL,
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function authorizationHeader() {
  return {
    headers: {
      Authorization: `Bearer ${Cookies.get('talent__api_access_token')}`,
    },
  };
}

export function apiAuthUrl(provider, code, useParam) {
  let url = `/auth/${provider}/callback`;

  if (useParam) {
    url += `?code=${code}`;
  }
  return url;
}

export const retryableHttpRequest = async (
  url,
  attemptNumber = 0,
  maxRetries = 3,
  method = 'GET',
  data,
) => {
  try {
    const response = await api({
      method,
      url,
      data,
    });
    return response.data;
  } catch (error) {
    if (attemptNumber > maxRetries) {
      Bugsnag.notify(
        new Error(
          `Max number of retries reached. Error: ${error.message} for ${url} endpoint`,
        ),
      );
      throw new Error(error);
    }
    setTimeout(() => {
      retryableHttpRequest(url, attemptNumber + 1, maxRetries, method, data);
    }, 500);
  }
};
