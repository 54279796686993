import classNames from 'classnames';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import './KoiToast.scss';

export default function KoiToast({
  position = 'top-center',
  delay = 3000,
  className,
  type,
  show,
  message,
  onClose,
  ...props
}) {
  return (
    <ToastContainer position={position}>
      <Toast
        autohide
        className={classNames('__koi-toast', className)}
        delay={delay}
        show={show}
        bg={type}
        onClose={onClose}
        {...props}
      >
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
