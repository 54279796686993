import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import PreviewImg from '../../assets/fakeDoor/screenshot.png';
import { api } from '../../utils/api';
import Tracking from '../../utils/tracking';

import './SplashScreen.scss';

const FakeDoorModal = ({
  show,
  onHide = () => {},
  onHideParent = () => {},
}) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState('');

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      Tracking.trackUserClicked({
        widget_name: 'trueprofile-splashscreen-modal-submit',
      });
      Tracking.trackUserAction('SplashScreenModalSubmit');
      await api.post('/interested_trueprofile_users', {
        interested_user: {
          email: email,
        },
      });
      onHide('AVOID_LOG');
      onHideParent();
    } catch (error) {
      setError('Email already taken.');
    }

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      className={'inner-modal'}
      dialogClassName="fake-door-modal-component"
      centered
      onHide={onHide}
    >
      <Modal.Body className="inner-modal-content">
        <div className="__splash-screen">
          <span className="close-icon-modal" onClick={onHide}>
            &times;
          </span>
          <div className="profile-preview-modal">
            <img src={PreviewImg} alt="Profile Preview" />
          </div>
          <div className="modal-text-content">
            <h2 className="modal-title">Oops! You’re a step ahead of us.</h2>
            <p className="modal-subtitle">
              We’re nearly ready to help you build a profile that truly
              represents you. Be the first to know when it launches—sign up
              below!
            </p>
            <div>
              <div className="modal-form">
                <div className="input-wrapper">
                  <label className="modal-label" for="__modal-email">
                    Your email address
                  </label>
                  <input
                    id="__modal-email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError('');
                    }}
                    type="email"
                    className="modal-input"
                  />
                  {error && <p className="error-text">{error}</p>}
                </div>
                <button
                  onClick={handleSendEmail}
                  className="cta-button modal-button"
                >
                  {loading ? <Spinner /> : 'Notify me'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FakeDoorModal;
