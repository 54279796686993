export const formatPhoneNumber = (value, internationalSupport = false) => {
  // Remove all non-digit characters, but keep leading '+' if present
  let normalizedValue = value.replace(/(?!^\+)\D/g, '');

  // Check if the international support is enabled and the number starts with '+'
  if (internationalSupport && normalizedValue.startsWith('+')) {
    return normalizedValue; // Return as is for international numbers
  } else {
    // Ensure the string only contains digits for US formatting
    normalizedValue = normalizedValue.replace(/\D/g, '');

    if (normalizedValue.length > 10) {
      normalizedValue = normalizedValue.slice(0, 10); // Limit to 10 digits
    }

    let formattedValue = '';
    // Apply US formatting ###-###-####
    for (let i = 0; i < normalizedValue.length; i++) {
      if (i === 3 || i === 6) {
        formattedValue += '-';
      }
      formattedValue += normalizedValue[i];
    }
    return formattedValue;
  }
};

export const getDiscountedPrice = (fullPrice, discountPercentage) => {
  const parsedPrice = fullPrice.includes('$') ? fullPrice.slice(1) : fullPrice;
  const discountAmount = (parsedPrice * discountPercentage) / 100;
  const discountedPrice = parsedPrice - discountAmount;
  const formattedPrice = discountedPrice.toFixed(2);

  return fullPrice.includes('$') ? `$${formattedPrice}` : formattedPrice;
};
