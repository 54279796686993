import classNames from 'classnames';
import Alert from 'react-bootstrap/Alert';

import './KoiAlert.scss';

export default function KoiAlert({ className, children, ...props }) {
  return (
    <Alert className={classNames('__koi-alert', className)} {...props}>
      {children}
    </Alert>
  );
}
