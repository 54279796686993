import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import SplashScreenImg from '../../assets/fakeDoor/not-ready-modal.png';
import { FeatureFlags } from '../../constants/featureFlags';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import KoiButton from '../../layout/koi/KoiButton';
import Tracking from '../../utils/tracking';

import FakeDoorModal from './FakeDoorModal';

import './SplashScreen.scss';

const NotReadyForFreeTrialModal = ({ onCloseSplashScreen, show }) => {
  const [openModal, setOpenModal] = useState(false);
  const fakeDoorEnabled = useFeatureFlag(FeatureFlags.FAKE_DOOR, false);

  if (!fakeDoorEnabled) {
    return <></>;
  }
  return (
    <Modal
      show={show}
      centered
      onHide={onCloseSplashScreen}
      dialogClassName={openModal ? 'not-ready-modal-parent' : 'not-ready-modal'}
    >
      <Modal.Body className="modal-body-not-ready">
        <div className="__splash-screen">
          <span className="close-icon-button" onClick={onCloseSplashScreen}>
            &times;
          </span>
          <div className="not-ready-screen">
            <div className="left-side">
              <img src={SplashScreenImg} alt="Profile Preview" />
            </div>

            <div className="right-side">
              <h2 className="not-ready-title">
                Not ready to start your free trial?
              </h2>
              <p className="not-ready-text">
                Elevate your brand with <strong>Trueprofile</strong>—a free
                profile that showcases not only your skills, but also your
                unique professional personality. You've already put in the hard
                work, so why not take the next step?
              </p>
              <ol className="not-ready-text">
                <li>Quickly create your profile with just one click</li>
                <li>Easily add it to LinkedIn™</li>
                <li>Share it to expand your network</li>
              </ol>
              <KoiButton
                className="cta-button"
                onClick={() => {
                  Tracking.trackUserClicked({
                    widget_name: 'not-ready-for-free-trial-cta',
                  });
                  setOpenModal(true);
                }}
              >
                Build your FREE Trueprofile
              </KoiButton>
            </div>
          </div>

          <div>
            <FakeDoorModal
              show={openModal}
              onHideParent={() => onCloseSplashScreen('AVOID_LOG')}
              onHide={(event) => {
                setOpenModal(false);
                if (event !== 'AVOID_LOG') {
                  Tracking.trackUserClicked({
                    widget_name: 'trueprofile-splashscreen-modal-close',
                  });
                }
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NotReadyForFreeTrialModal;
