import { useLinkedIn } from 'react-linkedin-login-oauth2';

import { useGoogleLogin } from '@react-oauth/google';

import { ReactComponent as GoogleIcon } from '../../assets/icons/koi/google-icon.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/koi/linkedin-icon.svg';
import KoiButton from '../../layout/koi/KoiButton';
import { getEnvSetting } from '../../utils/envSettings';

import './SSOButtons.scss';

const linkedInClientId = getEnvSetting('LINKEDIN_CLIENT_ID');

export default function SSOButtons({
  googleButtonText = 'Log in with Google',
  linkedInButtonText = 'Log in with LinkedIn',
  disabled = false,
  onSuccessGoogle,
  onSuccessLinkedIn,
  onFailure,
}) {
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => onSuccessGoogle(codeResponse),
    onFailure: () => onFailure('Google'),
    flow: 'auth-code',
  });

  const { linkedInLogin } = useLinkedIn({
    clientId: linkedInClientId,
    redirectUri: `${window.location.origin}/oauth/linkedin`,
    onSuccess: onSuccessLinkedIn,
    onError: () => onFailure('LinkedIn'),
    scope: 'openid,profile,email',
  });

  return (
    <div className="__sso">
      <KoiButton
        className="social-btn"
        icon={<GoogleIcon />}
        variant="secondary"
        disabled={disabled}
        onClick={googleLogin}
      >
        {googleButtonText}
      </KoiButton>

      <KoiButton
        className="social-btn"
        icon={<LinkedInIcon />}
        variant="secondary"
        disabled={disabled}
        onClick={linkedInLogin}
      >
        {linkedInButtonText}
      </KoiButton>
    </div>
  );
}
