import { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';

const initialState = {
  currentStep: 0,
  currentView: 'UPLOAD_RESUME',
  currentJob: null,
  coreData: null,
};

function reducer(draft, action) {
  switch (action.type) {
    case 'resumeBuilder/goToStep':
      const { step, view } = action.payload;
      draft.currentStep = step;
      draft.currentView = view;
      break;

    case 'resumeBuilder/nextStep':
      draft.currentStep = draft.currentStep + 1;
      break;

    case 'resumeBuilder/setJob':
      draft.currentJob = action.payload;
      break;

    default:
      console.warn('Non-existing action type:', action.type);
  }
}

// Create the context
const ResumeBuilderContext = createContext(null);

// Create a custom provider component
export function ResumeBuilderProvider({ children }) {
  const [rbState, rbDispatch] = useImmerReducer(reducer, initialState);

  return (
    <ResumeBuilderContext.Provider value={{ rbState, rbDispatch }}>
      {children}
    </ResumeBuilderContext.Provider>
  );
}

// Custom hook to use the ResumeBuilderContext
export function useResumeBuilderContext() {
  const { rbState, rbDispatch } = useContext(ResumeBuilderContext);
  return { rbState, rbDispatch };
}
