import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

import { getEnvSetting } from '../utils/envSettings';
import { getLaunchDarklySessionKey } from '../utils/util';

const launchDarklyClientId = getEnvSetting('LAUNCH_DARKLY_CLIENT_ID');

export default function LaunchDarklyProvider({ children }) {
  const [LDProvider, setLDProvider] = useState(null);

  useEffect(() => {
    if (!launchDarklyClientId) {
      return;
    }

    const LD_CONTEXT_KEY = getLaunchDarklySessionKey();

    const ldConfig = {
      clientSideID: launchDarklyClientId,
      context: {
        kind: 'user',
        key: LD_CONTEXT_KEY,
      },
      reactOptions: {
        useCamelCaseFlagKeys: false,
      },
    };

    (async () => {
      const Provider = await asyncWithLDProvider(ldConfig);
      setLDProvider(() => Provider);
      console.info('LDProvider with asyncWithLDProvider initialized');
    })();
  }, []);

  if (LDProvider) {
    return <LDProvider>{children}</LDProvider>;
  }

  return null;
}
