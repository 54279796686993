const GTM_EVENTS_MAP = {
  startAssessment: {
    production: '5M4MCNzWqKgZENOw58Q9',
    qa: 'NHHWCN-_1KsZENOw58Q9',
  },
  submitAssessment: {
    production: '7rLmCN_WqKgZENOw58Q9',
    qa: '9YAUCOW_1KsZENOw58Q9',
  },
  createAccount: {
    production: 'etMTCNbWqKgZENOw58Q9',
    qa: '1VEKCNa_1KsZENOw58Q9',
  },
  startResumeBuilder: {
    production: 'Dj__COLWqKgZENOw58Q9',
    qa: 'YSFQCOK_1KsZENOw58Q9',
  },
  uploadResume: {
    production: 'fSETCOXWqKgZENOw58Q9',
    qa: 'h7bwCOi_1KsZENOw58Q9',
  },
  completeCareerHistory: {
    production: '38SqCOjWqKgZENOw58Q9',
    qa: 'qzvCCNm_1KsZENOw58Q9',
  },
  generateFirstResume: {
    production: '4OL7COvWqKgZENOw58Q9',
    qa: 'gtgCCNy_1KsZENOw58Q9',
  },
  completePurchase: {
    production: 'vQt_CNPWqKgZENOw58Q9',
    qa: '0iRxCNO_1KsZENOw58Q9',
  },
};

export default GTM_EVENTS_MAP;
