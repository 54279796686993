import { isNil } from 'lodash';
import { useLocation, useSearchParams } from 'react-router-dom';

const usePaywallDiscountModal = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const tab = searchParams.get('tab');
  const utmSource = searchParams.get('utm_source');
  const utmMedium = searchParams.get('utm_medium');
  const utmDiscount = parseInt(searchParams.get('utm_discount'), 10);
  const utmStripeId = searchParams.get('utm_stripe_id');
  const onDashboard = location.pathname.includes('dashboard');

  const showPaywallDiscountModal =
    onDashboard &&
    tab === 'documents' &&
    utmSource === 'brevo' &&
    utmMedium === 'email' &&
    !isNaN(utmDiscount) &&
    [20, 30, 50].includes(utmDiscount) &&
    !isNil(utmStripeId);

  return {
    utmSource,
    utmMedium,
    utmDiscount,
    utmStripeId,
    showPaywallDiscountModal,
  };
};

export default usePaywallDiscountModal;
