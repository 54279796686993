export function envSettingTrue(setting) {
  return process.env[`REACT_APP_${setting}`]?.toLowerCase() === 'true';
}

export function getEnvSetting(setting) {
  return process.env[`REACT_APP_${setting}`];
}

export const QA_HOSTS = [
  'mypsycaptest.com',
  'mypsycap-preview.com',
  'talent-passport.vercel.app',
  'truepathqa.ai',
  'mytrudyqa.com',
  'localhost:5005',
];

export const PROD_HOSTS = ['truepath.ai', 'mytrudy.com'];
export const STAGING_HOSTS = ['truepathstaging.ai', 'mytrudystaging.com'];

export const ENV_PROD = PROD_HOSTS.some(
  (host) =>
    host === window.location.hostname || window.location.host.endsWith(host),
);

export const ENV_STAGING = STAGING_HOSTS.some(
  (host) =>
    host === window.location.hostname || window.location.host.endsWith(host),
);

export const ENV_QA = QA_HOSTS.some(
  (host) =>
    host === window.location.hostname || window.location.host.endsWith(host),
);

export const ENV_LOCAL = process.env.NODE_ENV === 'development';
export const ENV_PREVIEW = window?.location?.host.includes('mypsycaptest');

export const SIGN_UP_FIRST = envSettingTrue('SHOW_SIGNUP_FIRST');

export const ENV_TEST = window.Cypress;

export const getEnvironment = () => {
  if (ENV_PROD) {
    return 'production';
  }
  return ENV_QA ? 'qa' : 'local';
};

export const getDomain = () => {
  const currentUrl = window.location.href;
  const isTrudyDomain = currentUrl.toLocaleLowerCase().includes('mytrudy');

  if (ENV_PROD) {
    return isTrudyDomain ? '.mytrudy.com' : '.truepath.ai';
  }

  if (ENV_QA) {
    return isTrudyDomain ? '.mytrudyqa.com' : '.truepathqa.ai';
  }

  if (ENV_STAGING) {
    return isTrudyDomain ? '.mytrudystaging.com' : '.truepathstaging.ai';
  }

  if (ENV_PREVIEW) {
    return `.mypsycaptest.com`;
  }

  if (ENV_LOCAL) {
    return 'localhost:5005';
  }

  return isTrudyDomain ? '.mytrudy.com' : '.truepath.ai';
};
