import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const scroll = useCallback(() => {
    // Disable the browser's automatic scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    scroll();
  }, [pathname, scroll]);

  useEffect(() => {
    scroll();
  }, [scroll]);

  return null;
}
