import classNames from 'classnames';
import Button from 'react-bootstrap/Button';

import './KoiButton.scss';

export default function KoiButton({
  className,
  icon = null,
  children,
  ...props
}) {
  return (
    <Button
      className={classNames(
        '__koi-button',
        { '__koi-button-icon': icon },
        className,
      )}
      {...props}
    >
      {icon ? (
        <>
          {icon}
          <span>{children}</span>
        </>
      ) : (
        children
      )}
    </Button>
  );
}
