import classNames from 'classnames';

import { ReactComponent as QuoteIcon } from '../../assets/icons/koi/quote-icon.svg';
import usePricingExperimentFlag from '../../hooks/usePricingExperimentFlag';

import './Testimonial.scss';

export default function Testimonial({ details, quote, userName, userImg }) {
  const PRICING_EXPERIMENT_ENABLED = usePricingExperimentFlag();
  return (
    <div
      className={classNames('__testimonial', {
        '__testimonial-variant': PRICING_EXPERIMENT_ENABLED,
      })}
    >
      <QuoteIcon />
      <div className="quote">{quote}</div>
      <div className="user">
        {userImg}
        <div>
          <div className="name">{userName}</div>
          <div className="details">{details}</div>
        </div>
      </div>
    </div>
  );
}
