import { useContext } from 'react';
import classNames from 'classnames';

import { ReactComponent as CloseIcon } from '../../assets/icons/koi/close-icon.svg';
import { ReactComponent as FourDaysIcon } from '../../assets/icons/koi/trial-4days.svg';
import { ReactComponent as SevenDaysIcon } from '../../assets/icons/koi/trial-7days.svg';
import { ReactComponent as TodayIcon } from '../../assets/icons/koi/trial-today.svg';
import KoiButton from '../../layout/koi/KoiButton';
import { Highlight } from '../../layout/koi/KoiTitle';
import AppContext from '../../store/AppContext';
import { addDaysToDate } from '../../utils/dates';
import Tracking from '../../utils/tracking';

import { MONTHLY_TIERS, PLANS_PRICING_EXPERIMENT } from './constants';

const FreeTrialScreen = ({
  onHide,
  onSelectPlan,
  setShowPricingExperimentScreen,
  setCurrentScreen,
  selectedPlan,
}) => {
  const [state] = useContext(AppContext);
  const { currentUser } = state;

  return (
    <div className="full-width">
      <header className="free-trial-screen-header">
        <h3 className="free-trial-screen-title">
          Start your <Highlight>free trial</Highlight> today to <br /> download
          your resume.
        </h3>
        <div
          className="close-icon"
          onClick={() => {
            onHide();
          }}
        >
          <CloseIcon />
        </div>
        <div className="tiers-selector">
          {PLANS_PRICING_EXPERIMENT.filter((item) => item.tier).map((plan) => (
            <span
              className={classNames('dynamic-plan-selector-badge', {
                'dynamic-plan-selector-badge-selected':
                  selectedPlan.tier === plan.tier,
              })}
              onClick={() => {
                onSelectPlan(plan);
              }}
            >
              <p className="mulish">{plan.title}</p>
            </span>
          ))}
        </div>
        <div className="copy-container">
          <p className="trial-screen-copy">
            {MONTHLY_TIERS[selectedPlan.tier]?.trialScreenCopy}
          </p>
          <p className="trial-screen-disclaimer">
            {MONTHLY_TIERS[selectedPlan.tier]?.trialScreenDisclaimer}
          </p>
        </div>
      </header>

      <section className="full-width bottom-section">
        <div className="explanation-wrapper">
          <div className="timeline-icons">
            <TodayIcon />
            <FourDaysIcon />
            <SevenDaysIcon />
          </div>
          <div>
            <div className="item first-item-mobile">
              <div>
                <strong className="item-title">Today</strong>
                <p className="item-description">
                  Get instant access to unlimited resume and cover letter
                  creation.{' '}
                </p>
              </div>
            </div>

            <div className="item second-item-mobile">
              <div>
                <strong className="item-title">Day 4</strong>
                <p className="item-description">
                  We’ll send you a reminder that your trial is ending soon.
                </p>
              </div>
            </div>

            <div className="item">
              <div>
                <strong className="item-title">Day 7</strong>
                <p className="item-description">
                  You'll be charged{' '}
                  {MONTHLY_TIERS[selectedPlan.tier]?.fullPrice} on{' '}
                  {addDaysToDate(7)}, cancel anytime before.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="free-trial-button-wrapper">
          <KoiButton
            variant="link"
            onClick={() => {
              setShowPricingExperimentScreen(false);
            }}
          >
            See all plans
          </KoiButton>
          <KoiButton
            data-cy="start-free-trial-btn"
            onClick={() => {
              setShowPricingExperimentScreen(false);
              Tracking.trackUserClicked(
                {
                  widget_name: 'Pricing',
                  tier: selectedPlan.statName,
                  from_view: 'Plans modal',
                },
                currentUser,
              );
              setCurrentScreen(!currentUser?.registered ? 'second' : 'third');
            }}
          >
            Start my free trial
          </KoiButton>
        </div>
      </section>
    </div>
  );
};

export default FreeTrialScreen;
